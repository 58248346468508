import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

export const Container = styled.div`
  .ant-layout-sider.ant-layout-sider-light.ant-layout-sider-has-trigger {
    border-right: 1px solid #efefef;
    background-color: ${({ theme }) => theme.colors.primary};

    .ant-layout-sider-trigger {
      border-right: 1px solid #efefef;
    }
  }

  .ant-menu-item {
    font-weight: 600;
    height: 50px;

    &:not(.ant-menu-item-selected) {
      color: #b1b0b5;
    }

    &.ant-menu-item-only-child {
      height: 50px;
    }
  }

  .ant-menu-submenu {
    font-weight: 600;

    .ant-menu-submenu-title {
      height: 50px;
    }

    &:not(.ant-menu-item-selected) {
      color: #b1b0b5;
    }
  }

  .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.ant-menu-inline-collapsed {
    svg {
      margin-top: 12px;
    }
  }
`;

export const Logo = styled(Link)`
  margin: 16px 0 16px 5px;
  height: 50px;
  border-radius: 6px;
  transition: 0.3s;
  justify-content: center;
  display: flex;
  align-items: center;
  min-width: 50px;
  > img {
    height: 50px;
  }
`;

export const CustomMenuItem = styled(Menu.Item)`
  color: ${props => props.theme.colors.primary} !important;

  &:hover {
    color: 'white' !important;
  }
`;

export const CustomSingleMenuItem = styled(Menu.Item)`
  background-color: ${props =>
    props.selected
      ? props.theme.colors.tertiary
      : props.theme.colors.primary} !important;

  color: ${props => props.theme.colors.white} !important;

  &:hover {
    color: 'white' !important;
  }
`;

export const CustomSubMenu = styled(Menu.SubMenu)`
  .ant-menu-submenu-title,
  .ant-menu-submenu-arrow {
    color: ${props => props.theme.colors.white} !important;

    &:hover {
      color: 'white' !important;
    }
  }
`;
