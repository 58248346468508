import styled, { createGlobalStyle } from 'styled-components';

export const DashboardWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;

    & > :nth-child(2) {
      display: none;
    }
  }
`;

export const Container = styled.div`
  padding-bottom: 40px;
  margin: 30px 10px 0px;
`;

export const Title = styled.div`
  > h1 {
    margin: 0 0 10px 0;
    padding: 0 15px;
    position: relative;
    color: #67696c;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 4px;
      height: 100%;
      background: ${({ theme }) => theme.colors.primary};
    }
  }

  > p {
    margin: 0;
    padding: 0 15px;
    color: #676a6c;
    font-size: 0.875rem;
    font-weight: lighter;
  }
`;

export default createGlobalStyle`
  h1,
  h2,
  h3 {
    font-weight: 100;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color:#cce4e6;
}
  h1,
  h2,
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 1.875rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1rem;
  }

  body {
    background: #f8f8f8;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  input, select {
    height: 40px;
  }

  .validation-errors {
    margin-top: 4px;
    min-height: 20px;
    color: #ff0033;
  }

  .ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .ant-select {
    width: 100%;
  }

  .react-datepicker__month-wrapper {
    > div {
      padding: 10px 0;

      @media (max-width: 768px) {
        padding: 5px 0;
      }
    }
  }

  .react-datepicker__navigation-icon::before,
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    top: 12px;
  }

  .page-header {
    padding: 16px 24px 24px 24px;
    border: 1px solid #e7eaec;
    border-radius: 10px;

    .ant-page-header-heading-title {
      color: #333333;
      font-weight: 500;
    }

    .ant-page-header-content {
      padding-top: 10px;
    }
  }
`;
